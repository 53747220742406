import React from "react";
import AssetQrTableWrapper from "../../../../components/DataTableComponent/DataTable.component";
import moment from "moment";
import { useTranslation, Trans } from "react-i18next";
import Checkbox from "../../../../components/Checkbox/Checkbox.component";

import styled from "styled-components";
const DownloadStyled = styled.div`
  color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  font-size: 18px !important;
`;

const AnchorStyled = styled.a`
  cursor: pointer;
  text-align: center;
`;

const NoReportsDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 20px !important;
  overflow-y: hidden !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  clear: both;
`;

const StyledSpan = styled.span`
  height: ${(props) => `calc(100vh - ${props.height}px)`};
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
  clear: both;
`;

const AssetQrCodeListTable = ({
  data,
  isSiteAdmin,
  isSuperAdmin,
  handleSelectAll,
  isAllSelected,
  handleCheckboxClick,
  downloadReportSetIds,
  downloadQrCode,
  paginationOptions,
  noDataMessage,
  loading,
  pageSize,
  pageNo,
  totalPageReport,
  nextPage,
  previousPage,
  handlePageLength,
  dropdownAccountId,
  rowHeight,
  accountNameArray,
}) => {
  const { t } = useTranslation();

  const userTableRowData = [
    {
      name: "Account",
      keyName: "accountName",
      space: 2,
      isNestedVar2: true,
      fieldName1: "accountId",
      fieldName2: "email",
    },
    {
      name: "Worksite",
      keyName: "worksite",
      space: 1,
      isNestedVar2: true,
      fieldName1: "siteId",
      fieldName2: "name",
    },
    {
      name: "Asset Type",
      keyName: "assetType",
      space: 1,
      isNestedVar2: true,
      fieldName1: "assetTypeId",
      fieldName2: "AssetTypeName",
      fieldName3: "AssetTypeName",
    },

    { name: "Asset Name", keyName: "assetName", space: 1, fieldName: "assetName" },

    { name: "Added On", space: 1, fieldName: "createdAt" },
    { name: "Action", space: 2, fieldName: "inspectionCountMonthly" },
  ];

  const ActionCell = ({ row }) => {
    return (
      <>
        <AnchorStyled name={data?.assetName} onClick={() => downloadQrCode(row._id)}>
          <DownloadStyled className="icon icon-download" />
        </AnchorStyled>
      </>
    );
  };

  const columns = userTableRowData
    .filter(
      (data) => !((!isSuperAdmin && data.name === "Account") || (isSiteAdmin && data.name === "Worksite"))
    )
    .map((data) => {
      if (data.name === "Account") {
        return {
          name: t(data.name),
          selector: (row) => {
            const accountName = accountNameArray.get(row?.accountId);
            return accountName || "NA";
          },
          sortable: true,
        };
      }

      if (data.name === "Worksite") {
        return {
          name: t(data.name),
          selector: (row) => row?.siteId?.name || "NA",
          sortable: true,
        };
      }

      if (data.name === "Asset Type") {
        return {
          name: t(data.name),
          selector: (row) => row?.assetTypeId?.AssetTypeName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Asset Name") {
        return {
          name: t(data.name),
          selector: (row) => row?.assetName || "NA",
          sortable: true,
        };
      }

      if (data.name === "Added On") {
        return {
          name: t(data.name),
          selector: (row) => moment(row?.createdAt).format("DD/MM/YYYY") || "NA",
          sortable: true,
        };
      }

      if (data.name === "Action") {
        return {
          name: t(data.name),
          centered: true,
          selector: (row) => <ActionCell row={row} />,
          width: 120,
        };
      }

      return {
        name: t(data.name),
        selector: (row) => row[data.fieldName] || "NA",
        sortable: true,
      };
    });

  const mobileCheckboxDateTime = [
    { columnName: "Account", className: "accountStyle" },
    { columnName: "Worksite", className: "worksiteStyle" },
    { columnName: "Added On", className: "worksiteStyle" },
  ];

  const mobileCheckboxAccordion = ["Account", "Worksite", "Asset Type", "Asset Name", "Action"];

  if (!Array.isArray(data)) {
    return <NoReportsDiv>{t("No Assets List Available")}</NoReportsDiv>;
  }

  const columnsWithCheckboxes = [
    {
      name: <Checkbox type="checkbox" onClick={(e) => handleSelectAll(e)} checked={isAllSelected} />,
      width: 34,
      selector: (row) => (
        <Checkbox
          onClick={(e) => handleCheckboxClick(e, row)}
          value={row}
          checked={downloadReportSetIds.has(row._id)}
        />
      ),
    },
  ];

  columns.unshift(...columnsWithCheckboxes);

  return (
    <>
      {isSuperAdmin && (!dropdownAccountId || dropdownAccountId === "all") ? (
        <StyledSpan height={rowHeight}>
          <Trans>Please select an account to view details</Trans>
        </StyledSpan>
      ) : (
        <AssetQrTableWrapper
          title=""
          columns={columns}
          data={data}
          mobileCheckboxDateTime={mobileCheckboxDateTime}
          mobileCheckboxAccordion={mobileCheckboxAccordion}
          responsive
          dense
          loading={loading}
          rowHeight={rowHeight}
          scrollable={true}
          fixedHeader
          mobileCheckboxOnly
          mobileDateTimeOnly
          mobileActionItemOnly
          noDataMessage={noDataMessage}
          rowsPerPageOptions={[5]}
          pagination={true}
          pageSize={pageSize}
          pageNo={pageNo}
          nextPage={nextPage}
          previousPage={previousPage}
          handlePageLength={handlePageLength}
          paginationOptions={paginationOptions}
          totalPageReport={totalPageReport}
        />
      )}
    </>
  );
};

export default AssetQrCodeListTable;
