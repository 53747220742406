import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Button from "../Button/Button.component";
import {
  UploadMessage,
  UploadTitle,
  IconDiv,
  TextUploadMessage,
  TextUserAddedUpload,
  S3InstructionModal,
  DownloadFileMessage,
} from "./UploadMessage.component.styles";

const UploadMessageComponent = ({
  title,
  iconClassName,
  uploadMessage,
  additionalMessage,
  failedItemsDownloadLink,
  userAddedCount,
  userFailedCount,
  handleReuploadButton,
  handleContinueButton,
  errorMessage,
  ButtonLabelContinue,
  ButtonLabelReupload,
  instructionModal,
  additionalMessageError,
  additionalMessageSuccess,
  instructionLine1,
  instructionLine2,
}) => {
  const { t } = useTranslation();
  return (
    <UploadMessage>
      <UploadTitle className="text-kanit">
        <Trans>{title}</Trans>
      </UploadTitle>

      <IconDiv>
        <i className={`icon ${iconClassName}`} />
      </IconDiv>

      <TextUploadMessage>
        <Trans>{uploadMessage}</Trans>.
      </TextUploadMessage>
      {additionalMessageSuccess && (
        <TextUserAddedUpload>
          <span>
            <Trans>{additionalMessageSuccess}</Trans>
            {userAddedCount && <span className="greenSuccess"> {userAddedCount} </span>}
          </span>
        </TextUserAddedUpload>
      )}
      {additionalMessageError && (
        <TextUserAddedUpload>
          <span>
            {additionalMessageError.map((additionalError, index) => (
              <>
                <Trans key={index}>{additionalError}</Trans>
                {userAddedCount && <span className="redFailed"> {userAddedCount} </span>}
              </>
            ))}
          </span>
        </TextUserAddedUpload>
      )}

      {userFailedCount && (
        <DownloadFileMessage>
          <Trans>Click</Trans>
          <a className="clickHereA" href={failedItemsDownloadLink} target="_blank">
            <Trans>here</Trans>
          </a>
          <Trans>to download the CSV file of failed items</Trans>.
        </DownloadFileMessage>
      )}
      <br></br>
      {ButtonLabelContinue && (
        <Button style={{ width: "200px" }} label={ButtonLabelContinue} onClick={handleContinueButton} />
      )}
      <br></br>
      {ButtonLabelReupload && (
        <Button
          buttonType="ghost"
          style={{ width: "200px", marginBottom: "10px;" }}
          label={ButtonLabelReupload}
          onClick={handleReuploadButton}
        />
      )}
      <br></br>
      <br></br>

      {instructionModal && (
        <S3InstructionModal>
          <i className="icon icon-information-tooltip" />
          <span>
            {instructionModal.map((instruction, index) => (
              <>
                <Trans key={index}>{instruction}</Trans>.<br />
              </>
            ))}
          </span>
        </S3InstructionModal>
      )}
    </UploadMessage>
  );
};

export default UploadMessageComponent;
