import styled from "styled-components";

export const LoginRecordsStyle = styled.div`
  margin: 15px auto;
`;

export const StyledCol = styled.div`
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  @media screen and (max-width: 1100px) {
    padding-right: 15px;
    padding-left: 15px;
  }
`;
