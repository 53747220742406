//Cleanup this file when time permits and remove this comment

import React, { useState, useEffect } from "react";

// Redux
import { connect } from "react-redux";
import List from "../../../components/List";
import { selectChecklistOverview, getAllChecklist } from "../../../redux/checklists/action";

//custom components
import SearchBox from "../../../components/SearchBox/Searchbox.component";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
const CheckListStyled = styled.div`
  tr.tableTr td p {
    line-height: 34px !important;
  }
`;
const listType = require("../../../constant/listType");

function ChecklistList({
  checklistData,
  selectChecklistOverview,
  filterAccountId,
  checklistOverview,
  getAllChecklist,
  totalCount,
}) {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");

  const listDetail = {
    label: t("Your Checklists"),
    emptyListText: t("NO_CHECKLISTS_FOUND"),
    listType: listType.CHECKLIST,
  };

  useEffect(() => {
    selectChecklistOverview();
  }, [checklistData]);

  return (
    <CheckListStyled>
      <SearchBox
        label={t("Your Checklists")}
        placeholder={t("Search a checklist")}
        onChange={(e) => setSearchText(e.target.value)}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <div className="assetPageList assetPageData assettypename">
        <List
          hideHeader={true}
          listDetail={listDetail}
          listData={checklistData}
          selectItem={(id) => selectChecklistOverview(id)}
          selectedId={checklistOverview && checklistOverview._id}
          isFilterChange={filterAccountId}
          loadData={getAllChecklist}
          totalCount={totalCount}
          searchKey={searchText.length > 3 ? searchText : ""}
        />
      </div>
    </CheckListStyled>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  checklistData: state.checklist.checklists,
  checklistOverview: state.checklist.checklistOverview,
  totalCount: state.checklist.totalCount,
});

const mapDispatchToProps = (dispatch) => ({
  selectChecklistOverview: (checklist) => dispatch(selectChecklistOverview(checklist)),
  getAllChecklist: (paginationData, isResetState) => dispatch(getAllChecklist(paginationData, isResetState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistList);
