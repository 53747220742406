import styled from "styled-components";
export const AccountManagementPage = styled.div``;

export const DesktopView = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileView = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
`;


export const ActionBarWrapper = styled.div`
  position: relative;
`;
export const ActionBarButtons = styled.div`
  position: absolute;
  z-index: 5;
  width: 100%;
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 37%);
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 37%);
  -moz-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 37%);
  display: block;
  button {
    border: 0px;
    display: block;
    width: 100%;
    float: left;
    text-align: left;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    text-transform: capitalize;
    font-size: 16px;
    font-family: "Open Sans";
    i {
      display: none;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: block;
  float: left;
  width: 100%;
  text-align: left;
  margin: 10px 0px;

  button {
    margin-right: 10px;
  }
`;

export const AccountMainContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const AccountListing = styled.div`
  width: 340px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
`;

export const AccountDataList = styled.div`
  border-radius: 3px;
  width: 98%;
  margin-left: 0% !important;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;
