//TODO: Cleanup this file when time permits and remove this comment after that

import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

import QrCode from "../../../components/QrCodeAndDownload.component";
import AssetOverviewTab from "./AssetOverviewTab.component";
import ImageUpload from "../../../components/ImageRenderUpload.component";
import UpdateButtons from "../../../components/UpdateButtons.component";
import AdjustHourMeterModal from "./Modal/AdjustHourMeterModal";

import { toEditAssetName, resetState } from "../../../redux/asset/asset.action";
import SnackBar from "../SnackBar";
import { toAddAssetImage } from "../../../redux/asset/asset.action";
import {
  assetStatusUpdate,
  resetAllAssetStatus,
  toGenerateCSV,
  updateAsset,
  updateAssetItem,
} from "../../../redux/assets/action";

import { setEditForm } from "../../../redux/forms/action";
import { setEditAlertModal } from "../../../redux/modals/action";
import alertConstant from "../../../constant/messages/toaster.json";
import UnlockModal from "../../Modals/UnlockModal";
import { apiCall } from "../../../utils/apiCall";
import AssetOverviewChecklistTable from "./AssetOverviewChecklistTable.component";
import AssetMediaTab from "./AssetMediaTab.component";
import AssetOverviewInspectionReports from "./AssetOverviewInspectionReports.component";
import SwitchComponent from "../../../components/Switchbox/SwitchComponent";

import Button from "../../../components/Button/Button.component";
import {
  ButtonLocked,
  ButtonUnLocked,
  ManagementPanelWrapper,
  styles,
  AssetOverviewCard,
  AssetOverviewCardBody,
  MainWrapper,
  WrapperDiv,
  WrapperDivDetails,
  FullNameSpan,
  HeadingSpan,
  HeadingDiv,
  SwitchWrapper,
  ImageAndDescDiv,
  WrapperDesDiv,
  ImagWrapperDiv,
  StatusHead,
  StatusMobileRender,
  StatusDesktopRender,
  TabComponentDiv,
  NoSectionStyle,
  ButtonWrapperRow,
  ButtonWrapperCol,
  ButtonWraperAdjust,
} from "./AssetOverview.component.styles";
import { CacheRefs } from "../../../utils/cache/CacheRefs";
import SieraTabs from "../../../components/TabComponent/SieraTabs.Component";
import SieraTab from "../../../components/TabComponent/SieraTab.Component";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";
import config from "../../../config";

const qrType = require("../../../constant/qrType");
const s3BucketAssetImg = config.s3BucketAssetImg; //User profile image s3 path

const AssetOverview = ({
  assetOverview,
  success,
  resetState,
  loginDetails,
  assetStatusUpdate,
  assetStatusSuccess,
  resetAllAssetStatus,
  AssetLoading,
  AssetDetailLoading,
  updateAsset,
  updateAssetItem,
  setEditForm,
  editForm,
  setEditAlertModal,
  assetImageLoading,
  assetImageSuccess,
  toAddAssetImage,
  accountListById,
  isSuperAdmin,
  toGenerateCSV,
  generateCSVData,
  generateCSVLoading,
  assetStatusData,
  assetStatusFailed,
  currentTimeZone,
}) => {
  //const AssetStatus = assetOverview ? assetOverview?.AssetStatus : true;
  const [statusButton, setStatusButton] = useState(assetOverview?.AssetStatus);
  const [AssetName, setAssetName] = useState("");
  const [IsSubmitted] = useState(false);
  const [PreviewImage, setPreviewImage] = useState("");
  const [IsLoading, setIsLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [IsSuccess, setIsSuccess] = useState(false);
  const [IsError, setErrorSuccess] = useState(false);
  const [showEditButton, setShowEditButton] = useState(true);
  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [activeTab, setActiveTab] = useState("assetOverview");
  const [showAdjustMeterHourModal, setshowAdjustMeterHourModal] = useState(false);
  const { t } = useTranslation();

  const [showAlertModal, setShowAlertModal] = useState(false);
  //const [disableSwitch, setDisableSwitch] = useState(false);

  const changeStatus = (e) => {
    if (editForm) {
      let id = e.target.id;
      //setDisableSwitch(true);
      let AssetStatus = e.target.checked;
      assetStatusUpdate(id, { AssetStatus });
      //  setStatusButton(!statusButton);
    } else {
      setEditAlertModal(true);
    }
  };
  useEffect(() => {
    if (assetStatusFailed) {
      setShowAlertModal(true);
      //setDisableSwitch(false);
    }
  }, [assetStatusFailed]);

  useEffect(() => {
    if (assetStatusSuccess) {
      setStatusButton(!statusButton);
    }
  }, [assetStatusSuccess]);

  const labelStyle = {
    position: "relative",
    height: "0px",
    width: "100%",
    textAlign: "left",
    marginBottom: "0px",
    fontSize: "12px",
    color: "#919191",
    fontFamily: "Open Sans, sans-serif",
  };
  const handleUpdate = (event) => {
    let assetForm = document.getElementById("assetOverviewEdit");
    if (assetForm.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(false);
    event.preventDefault();

    setIsLoading(true);
    let {
      AssetStatus,
      // s3enabled,
      _id,
      assetName,
      assetDescription,
      assetAttributes,
      accountId,
      assetImage,
      assetMedia,
      productType,
      siteId,
    } = editData;
    let newPayload = {
      AssetStatus,
      // s3enabled,
      _id,
      assetName,
      assetDescription,
      assetAttributes,
      accountId,
      assetImage,
      assetMedia,
      productType,
      siteId,
    };

    apiCall(`/asset/updateasset/${event.target.id}`, { method: "PUT", data: newPayload })
      .then((response) => {
        if (response.data.success === true) {
          let { name, manager, timeZone, _id: siteId } = response?.data?.data?.siteId;
          updateAsset({
            _id,
            assetName,
            productType,
            siteId: { name, manager, timeZone, _id: siteId },
            assetAttributes,
          });
          setAssetName(assetName);
          setIsSuccess(true);
          setIsLoading(false);
          setEditForm(true);

          //below is to refresh the export data api after a asset channge
          CacheRefs.removeCacheForAssets();
          toGenerateCSV();
        } else {
          setErrorSuccess(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setErrorSuccess(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (assetOverview) {
      setAssetName(assetOverview.assetName);
      setPreviewImage(assetOverview.assetImage);
    }

    setStatusButton(assetOverview?.AssetStatus);
  }, [assetOverview]);

  const handleCloseSnackBar = () => {
    resetState();
    setIsSuccess(false);
    setErrorSuccess(false);
    resetAllAssetStatus();
    //setDisableSwitch(false);
  };

  useEffect(() => {
    //reset everything on unload
    return () => {
      handleCloseSnackBar();
    };
  }, []);
  const handleMessage = (success, assetImageSuccess, assetStatusSuccess, IsSuccess) => {
    if (success) {
      return t(alertConstant.asset_updated_successful);
    } else if (assetImageSuccess) {
      return t(alertConstant.asset_updated_successful);
    } else if (IsSuccess) {
      return t(alertConstant.asset_updated_successful);
    } else if (IsError) {
      return t(alertConstant.asset_updated_error);
    } else if (assetStatusSuccess) {
      if (statusButton) {
        return t("AssetActivatedMessage");
      } else {
        return t("AssetDeactivatedMessage");
      }
    }
  };

  let timeZone = currentTimeZone || assetOverview?.siteId?.timeZone;

  const messageLabel = handleMessage(success, assetImageSuccess, assetStatusSuccess, IsSuccess);

  const StatusComponent = () => {
    return (
      <PermissionCheck section={"DASHBOARD"} permissionName={"ASSET_MANAGEMENT"} actionName={"deactivate"}>
        <>
          <StatusHead>
            <Trans>Status</Trans>
          </StatusHead>

          <SwitchComponent
            id={assetOverview ? assetOverview._id : ""}
            checked={statusButton}
            onChange={changeStatus}
          />
        </>
      </PermissionCheck>
    );
  };

  const truncateTextFullName = (text, limit = 20) =>
    text && text.length > limit ? `${text.substring(0, limit)}...` : text;

  const [isIconOnly, setIsIconOnly] = useState(window.innerWidth <= 1100);
  useEffect(() => {
    const handleResize = () => {
      setIsIconOnly(window.innerWidth <= 1100);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ManagementPanelWrapper>
      <AssetOverviewCard className={`${assetOverview?.isLocked && "danger-outline"}`}>
        <UnlockModal
          modalShow={showModal}
          updateItem={(data) => {
            updateAssetItem(data);
          }}
          handleModalClose={() => setShowModal(false)}
          token={loginDetails?.token}
          data={selectedAsset}
        />
        <AssetOverviewCardBody>
          {(AssetLoading || AssetDetailLoading) && (
            <Row className="assetTopBarH">
              <Col lg={2}>
                <Skeleton circle={true} height={90} width={100} />
              </Col>
              <Col lg={4}>
                <Skeleton height={40} />
                <Skeleton height={10} count={3} />
              </Col>
              <Col lg={2}>
                <Skeleton height={100} />
              </Col>
              <Col lg={2}></Col>
              <Col lg={2}>
                <Skeleton height={60} width={60} />
              </Col>
            </Row>
          )}
          {assetOverview && !AssetLoading && !AssetDetailLoading && (
            <MainWrapper>
              <ImageAndDescDiv>
                <ImagWrapperDiv>
                  <ImageUpload
                    id={assetOverview._id}
                    updateImage={toAddAssetImage}
                    imageLoading={assetImageLoading}
                    src={PreviewImage ? s3BucketAssetImg + PreviewImage : ""}
                    key={PreviewImage}
                  />
                </ImagWrapperDiv>
                <WrapperDesDiv>
                  {/* <Col
                    lg={4}
                    className={`assetTextData ${AssetSectionStyles.UserDetailsTab}`}
                    style={{ marginTop: "-5px", zIndex: editForm ? 0 : 200 }}
                  >
                    {!editForm ? (
                      <div style={{ marginTop: "-8px" }}>
                        <label style={labelStyle}>
                          <Trans>Asset Name</Trans>
                        </label>
                        <Form.Control
                          value={editData ? editData.assetName : ""}
                          minLength={"1"}
                          maxLength={"64"}
                          onChange={(e) => setEditData({ ...editData, assetName: e.target.value })}
                          error={IsSubmitted && !AssetName}
                          helperText={IsSubmitted && !AssetName && t("Asset Name is required")}
                        />
                      </div>
                    ) : (
                      <div style={styles.titleName} className="title-style text_Repo_elips">
                        <span title={AssetName && AssetName}> {AssetName} </span>
                      </div>
                    )}
                  
                  </Col> */}
                  <WrapperDivDetails>
                    <FullNameSpan title={AssetName && AssetName}>
                      {truncateTextFullName(AssetName)}
                    </FullNameSpan>
                  </WrapperDivDetails>

                  <WrapperDivDetails>
                    <HeadingDiv>
                      <Trans>Asset Type</Trans>:
                    </HeadingDiv>
                    <HeadingSpan
                      title={
                        assetOverview && assetOverview.assetTypeId && assetOverview.assetTypeId.AssetTypeName
                      }
                    >
                      <Trans>
                        {assetOverview &&
                          assetOverview.assetTypeId &&
                          assetOverview.assetTypeId.AssetTypeName}{" "}
                      </Trans>
                    </HeadingSpan>
                  </WrapperDivDetails>

                  <WrapperDivDetails>
                    <HeadingDiv>
                      <Trans>Added On</Trans>:
                    </HeadingDiv>
                    <HeadingSpan>
                      {timeZone ? (
                        <>
                          {moment(assetOverview.createdAt)
                            .utcOffset(timeZone && timeZone.split && timeZone.split(" ")[0])
                            .format("MMMM DD YYYY")}
                        </>
                      ) : (
                        ""
                      )}
                    </HeadingSpan>
                  </WrapperDivDetails>

                  <WrapperDivDetails>
                    <HeadingDiv>
                      <Trans>Last Hour Meter Reading</Trans>:
                    </HeadingDiv>
                    <HeadingSpan>
                      {assetOverview?.lastHourMeterReading || assetOverview?.lastHourMeterReading == 0
                        ? assetOverview.lastHourMeterReading
                        : t("NA")}
                    </HeadingSpan>
                  </WrapperDivDetails>
                  <WrapperDivDetails>
                    <HeadingDiv>
                      <Trans>Worksite</Trans>:
                    </HeadingDiv>
                    <HeadingSpan title={assetOverview && assetOverview.siteId && assetOverview.siteId.name}>
                      {assetOverview && assetOverview.siteId && assetOverview.siteId.name}
                    </HeadingSpan>
                  </WrapperDivDetails>

                  {isSuperAdmin && assetOverview?.accountId && (
                    <WrapperDivDetails>
                      <HeadingDiv>
                        <Trans>AccountLabel</Trans>
                      </HeadingDiv>
                      <HeadingSpan title={accountListById?.[assetOverview?.accountId]?.companyName}>
                        {accountListById?.[assetOverview?.accountId]?.companyName}
                      </HeadingSpan>
                    </WrapperDivDetails>
                  )}
                </WrapperDesDiv>
              </ImageAndDescDiv>

              <WrapperDiv>
                <StatusMobileRender>
                  <StatusComponent />
                </StatusMobileRender>
                <QrCode qrFor={qrType.ASSET} qrDetail={assetOverview} bindUrl={true} />
              </WrapperDiv>

              <SwitchWrapper>
                <StatusDesktopRender>
                  <StatusComponent />
                </StatusDesktopRender>
              </SwitchWrapper>
            </MainWrapper>
          )}

          {!assetOverview && !AssetLoading && !AssetDetailLoading && (
            <>
              <NoSectionStyle>
                <p>
                  <Trans>NO_ASSET_IN_ACCOUNT</Trans>
                </p>
                <div className="clickDesktop">
                  <Trans>CLICK_ADD_ASSET_BUTTON</Trans>
                </div>
                <div className="tapMobile">
                  <Trans>CLICK_ADD_ASSET_BUTTON_MOB</Trans>
                </div>
              </NoSectionStyle>
            </>
          )}
          <TabComponentDiv>
            {assetOverview && !AssetLoading && !AssetDetailLoading && (
              <>
                <SieraTabs
                  variant="horizontalNew"
                  defaultActiveKey="userOverview"
                  activeKey={activeTab}
                  size="small"
                  onSelect={(key) => setActiveTab(key)}
                >
                  <SieraTab eventKey="assetOverview" title={t("Asset Overview")}>
                    <AssetOverviewTab
                      validated={validated}
                      editData={editData}
                      setEditData={setEditData}
                      editForm={editForm}
                      statusButton={statusButton}
                      setShowEditButton={setShowEditButton}
                    />
                  </SieraTab>
                  <SieraTab eventKey="assetChecklists" title={t("Asset Checklists")}>
                    <AssetOverviewChecklistTable statusButton={statusButton} />
                  </SieraTab>
                  <SieraTab eventKey="assetMedia" title={t("Asset Media")}>
                    <AssetMediaTab assetOverview={assetOverview} statusButton={statusButton} />
                  </SieraTab>
                  <SieraTab eventKey="inspectionReports" title={t("Inspection Reports")}>
                    <AssetOverviewInspectionReports assetId={assetOverview ? assetOverview._id : null} />
                  </SieraTab>
                </SieraTabs>

                <PermissionCheck
                  section={"DASHBOARD"}
                  permissionName={"ASSET_MANAGEMENT"}
                  actionName={"edit"}
                >
                  <ButtonWrapperRow>
                    {activeTab === "assetOverview" && (
                      <>
                        <ButtonWrapperCol>
                          {assetOverview?.isLocked ? (
                            <>
                              <ButtonUnLocked>
                                <Button
                                  iconClass="unlock"
                                  label={t("Unlock Asset")}
                                  onClick={() => {
                                    setShowModal(true);
                                    setSelectedAsset(assetOverview);
                                  }}
                                  {...(isIconOnly && { iconOnly: true })}
                                  buttonType="secondary"
                                />
                              </ButtonUnLocked>
                            </>
                          ) : (
                            <>
                              <ButtonLocked>
                                <Button
                                  iconClass="locked"
                                  label={t("Lock Asset")}
                                  onClick={() => {
                                    setShowModal(true);
                                    setSelectedAsset(assetOverview);
                                  }}
                                  {...(isIconOnly && { iconOnly: true })}
                                  buttonType="alert"
                                  disabled={!statusButton}
                                />
                              </ButtonLocked>
                            </>
                          )}
                          <ButtonWraperAdjust>
                            <Button
                              iconClass="asset-hour-adjust"
                              label={t("Adjust Hour Meter")}
                              onClick={() => {
                                setshowAdjustMeterHourModal(true);
                              }}
                              {...(isIconOnly && { iconOnly: true })}
                              buttonType="secondary"
                              disabled={!statusButton}
                            />
                          </ButtonWraperAdjust>
                        </ButtonWrapperCol>

                        <ButtonWrapperCol>
                          <UpdateButtons
                            editOverview={editForm}
                            setEditOverview={setEditForm}
                            statusButton={statusButton}
                            handleUpdate={handleUpdate}
                            IsLoading={IsLoading}
                            overviewDetail={assetOverview}
                          />
                        </ButtonWrapperCol>
                      </>
                    )}
                  </ButtonWrapperRow>
                </PermissionCheck>
              </>
            )}
          </TabComponentDiv>
          <AdjustHourMeterModal
            modalShow={showAdjustMeterHourModal}
            setModalShow={setshowAdjustMeterHourModal}
            setModalShowClose={() => setshowAdjustMeterHourModal(false)}
            editData={editData}
            currentHourMeterReading={assetOverview?.lastHourMeterReading || 0}
          />
        </AssetOverviewCardBody>
        <SnackBar
          isSuccess={
            (success || //|| assetImageSuccess
              assetStatusSuccess ||
              IsSuccess) &&
            t("AssetDeactivatedMessage") !== messageLabel
          }
          isFailed={t("AssetDeactivatedMessage") === messageLabel}
          label={messageLabel}
          handleClose={handleCloseSnackBar}
        />
      </AssetOverviewCard>
    </ManagementPanelWrapper>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  assetOverview: state.assets.AssetOverview,
  success: state.asset.Success,
  loading: state.asset.Loading,
  assetImageLoading: state.assets.AssetImageLoading,
  assetImageSuccess: state.assets.AssetImageSuccess,
  assetImageError: state.assets.AssetImageError,
  assetStatusSuccess: state.assets.AssetStatusSuccess,
  assetStatusMessage: state.assets.AssetStatusMessage,
  AssetLoading: state.assets.AssetLoading,
  AssetDetailLoading: state.assets.assetDetailLoading,
  editForm: state.forms.editForm,
  accountListById: state.dropdownFilters.accountListById,
  isSuperAdmin: state.user.isSuperAdmin,
  generateCSVData: state.assets.GeneratingCSVData,
  generateCSVLoading: state.assets.GenerateCSVLoading,
  assetStatusData: state.assets.assetStatusData,
  assetStatusFailed: state.assets.assetStatusFailed,
  currentTimeZone: state.settings.currentTimeZone,
});

const mapDispatchToProps = (dispatch) => ({
  toEditAssetName: (id, data) => dispatch(toEditAssetName(id, data)),
  resetState: () => dispatch(resetState()),
  toAddAssetImage: (data, id) => dispatch(toAddAssetImage(data, id)),
  assetStatusUpdate: (id, status) => dispatch(assetStatusUpdate(id, status)),
  resetAllAssetStatus: () => dispatch(resetAllAssetStatus()),
  updateAsset: (data) => dispatch(updateAsset(data)),
  updateAssetItem: (data) => dispatch(updateAssetItem(data)),
  setEditForm: (data) => dispatch(setEditForm(data)),
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
  toGenerateCSV: () => dispatch(toGenerateCSV()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetOverview);
