import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import AssetTypeList from "./AssetTypeList.component";
import ViewAssetTypes from "./ViewAssetTypes.component";
import BreadCrumb from "../../../components/BreadCrumb.component";
import { updateMenu, updateActiveNavItem } from "../../../redux/sideMenu/menu.action";
import { setAddAssetTypeModal, setModalWaitingToOpen, setAddAssetModal } from "../../../redux/modals/action";
import AddAssetTypeModal from "./Modal/AddAssetTypeModal/AddAssetTypeModal.component";
import CommonSuccessMessage from "../common/CommonSuccessMessage.component";
import Button from "../../../components/Button/Button.component";
import {
  ViewAssetTypeMainWrapper,
  DesktopView,
  MobileView,
  ButtonsContainer,
  AssetTypeDataList,
  AssetTypeListing,
  AssetTypeMainContainer,
  SearchFilter,
  SearchBoxWrapper,
  FilterWrapper,
  CloseBackDiv,
  CloseIconDiv,
  FilterBackDiv,
  FilterDesWrapper,
  FilterIconDiv,
  FilterOnclickDes,
  AssetMobileListWrapper,
  AssetTypeDetailHeading,
  RightIconImage,
} from "./ViewAssetTypesManagement.component.style";
import ActionButtons from "./AssetTypeManagementSection/ActionButtons.component";
import AssetTypeActionBar from "./AssetTypeManagementSection/AssetTypeActionBar.component";
import SearchBoxComponent from "./AssetTypeManagementSection/SearchBox.component";
import ManagementPanelStyles from "../Management/panels/ManagementPanel.module.css";
import ViewAssetTypeOverview from "./ViewAssetTypeOverview.component";
import { ReactComponent as rightIcon } from "../../../assets/rightIconwhite.svg";

const ViewAssetTypesManagement = ({
  history,
  loginDetails,
  updateMenu,
  isSuperAdmin,
  isSiteAdmin,
  updateActiveNavItem,
  setAddAssetTypeModal,
  addAssetTypeModal,
  setModalWaitingToOpen,
  setAddAssetModal,
  assetTypeOverview,
}) => {
  const [filterAccountId, setFilterAccountId] = useState("all");
  const [showAssetTypeDetail, setShowAssetTypeDetail] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    updateActiveNavItem("assetType");
  }, []);

  const [IsSuccess, setIsSuccess] = useState(false);
  const [IsError, setIsError] = useState(false);
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ViewAssetTypeMainWrapper>
      <DesktopView>
        <BreadCrumb />

        <ButtonsContainer>
          <ActionButtons setAddAssetTypeModal={setAddAssetTypeModal} />
        </ButtonsContainer>

        <AssetTypeMainContainer>
          {/* For user type listing */}
          <AssetTypeListing>
            <AssetTypeList
              filterAccountId={filterAccountId}
              style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
              setShowAssetTypeDetail={setShowAssetTypeDetail}
            />
          </AssetTypeListing>

          {/* For user type overview */}
          <AssetTypeDataList>
            <ViewAssetTypes setFilterAccountId={setFilterAccountId} />
            <ViewAssetTypeOverview setFilterAccountId={setFilterAccountId} />
          </AssetTypeDataList>
        </AssetTypeMainContainer>
      </DesktopView>

      <MobileView>
        <AssetTypeActionBar setAddAssetTypeModal={setAddAssetTypeModal} />

        {!showAssetTypeDetail ? (
          <>
            <SearchFilter>
              <SearchBoxWrapper>
                <SearchBoxComponent searchText={searchText} setSearchText={setSearchText} />
              </SearchBoxWrapper>
              {isSuperAdmin && (
                <FilterWrapper onClick={handleToggle}>
                  {isOpen ? (
                    <CloseBackDiv>
                      <CloseIconDiv className="icon icon-filter" />
                    </CloseBackDiv>
                  ) : (
                    <FilterBackDiv>
                      <FilterIconDiv className="icon icon-filter" />
                    </FilterBackDiv>
                  )}
                </FilterWrapper>
              )}
            </SearchFilter>

            {isSuperAdmin && isOpen && (
              <FilterOnclickDes>
                <FilterDesWrapper>
                  <ViewAssetTypes setFilterAccountId={setFilterAccountId} />
                </FilterDesWrapper>
              </FilterOnclickDes>
            )}

            <AssetMobileListWrapper>
              <AssetTypeList
                searchText={searchText}
                setSearchText={setSearchText}
                filterAccountId={filterAccountId}
                style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
                setShowAssetTypeDetail={setShowAssetTypeDetail}
              />
            </AssetMobileListWrapper>
          </>
        ) : (
          <>
            <AssetTypeDetailHeading className={`${ManagementPanelStyles.assetNameMobile} assetMobileCss`}>
              <span onClick={() => setShowAssetTypeDetail(false)}>Your Asset Type</span>{" "}
              {/* <img height="15" src={rightIcon} /> */}
              <RightIconImage className="icon icon-arrow-right"></RightIconImage>
              <Trans> {assetTypeOverview ? assetTypeOverview.AssetTypeName : ""}</Trans>
            </AssetTypeDetailHeading>

            <AssetTypeDataList>
              <ViewAssetTypeOverview setFilterAccountId={setFilterAccountId} />
            </AssetTypeDataList>
          </>
        )}
      </MobileView>

      <AddAssetTypeModal
        show={addAssetTypeModal}
        toogleModalClose={() => {
          setAddAssetTypeModal(false);
          setModalWaitingToOpen(false);
          setAddAssetModal(false);
        }}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
      <CommonSuccessMessage />
    </ViewAssetTypeMainWrapper>
  );
};

const mapStateToProps = (state) => ({
  assetTypeOverview: state.assets.AssetTypeOverview,
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  addAssetTypeModal: state.modals.addAssetTypeModal,
});

const mapDispatchToProps = (dispatch) => ({
  updateMenu: (menuState) => dispatch(updateMenu(menuState)),
  updateActiveNavItem: (data) => dispatch(updateActiveNavItem(data)),
  setAddAssetTypeModal: (data) => dispatch(setAddAssetTypeModal(data)),
  setModalWaitingToOpen: (data) => dispatch(setModalWaitingToOpen(data)),
  setAddAssetModal: (data) => dispatch(setAddAssetModal(data)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewAssetTypesManagement));
