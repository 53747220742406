import React, { useEffect, useState } from "react";
import axios from "axios";
import List from "../../../components/List";
import { connect } from "react-redux";
import { selectedWorksite } from "../../../redux/worksites/action";
import { getWorksites, getWorksiteDetailById } from "../../../redux/worksites/action";
import { setEditAlertModal } from "../../../redux/modals/action";
import { useTranslation } from "react-i18next";
import { apiCall } from "../../../utils/apiCall";
import config from "../../../config";
import styled from "styled-components";

const ListDivStyled = styled.div`
  width: 100%;
  .tableHeight {
    overflow-y: scroll;
    height: calc(100vh - 340px);
    overflow-x: hidden;
  }
  table tr.tableTr td p {
    width: 80%;
  }
`;

const baseUrl = config.baseUrl; //Base Url
const listType = require("../../../constant/listType");
function WorksiteList({
  loginDetails,
  worksites,
  worksitesOverview,
  searchText,
  dropdownAccountId,
  WorksiteSuccess,
  getWorksites,
  totalCount,
  WorksiteLoading,
  getWorksiteDetailById,
  editForm,
  setEditAlertModal,
  setShowWorksiteDetail,
}) {
  const { t } = useTranslation();

  const listDetail = {
    label: t("Your Worksites"),
    emptyListText: t("NO_WORKSITE_FOUND"),
    listType: listType.WORKSITE,
  };

  const [searchedWorksiteNew, setSearchedWorksiteNew] = useState([...worksites]);

  useEffect(() => {
    if (searchText.length > 2) {
      apiCall(`/site/search/${searchText}`)
        .then((response) => {
          console.log(response);
          console.log(searchedWorksiteNew);
          if (response.data.success) {
            setSearchedWorksiteNew([...response.data.data]);
          } else {
            setSearchedWorksiteNew([]);
          }
        })
        .catch((err) => {});
    } else {
      setSearchedWorksiteNew(worksites);
    }
  }, [searchText]);

  useEffect(() => {
    if (WorksiteSuccess) setSearchedWorksiteNew(worksites);
  }, [WorksiteSuccess]);

  return (
    <ListDivStyled>
      <>
        <List
          hideHeader={true}
          listDetail={listDetail}
          listData={worksites}
          selectItem={(id) => {
            /*   if (id == (worksitesOverview && worksitesOverview._id)) {
            return;
          } */
            if (editForm) {
              getWorksiteDetailById(id);
            } else {
              setEditAlertModal(true);
            }
            setShowWorksiteDetail();
          }}
          selectedId={worksitesOverview && worksitesOverview._id}
          isFilterChange={dropdownAccountId + searchText}
          loadData={getWorksites}
          totalCount={totalCount}
          dropdownAccountId={dropdownAccountId}
          searchKey={searchText.length > 3 ? searchText : ""}
          dataLoading={WorksiteLoading}
          showActiveInactiveTabs={true}
        />
      </>
    </ListDivStyled>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  worksites: state.worksites.Worksites || [],
  worksitesOverview: state.worksites.WorksiteOverview,
  WorksiteSuccess: state.worksites.WorksiteSuccess,
  totalCount: state.worksites.totalCount,
  dropdownAccountId: state.dropdownFilters.accountId,
  WorksiteLoading: state.worksites.WorksiteLoading,
  editForm: state.forms.editForm,
});

const mapDispatchToProps = (dispatch) => ({
  getWorksiteDetailById: (id) => dispatch(getWorksiteDetailById(id)),
  selectedWorksite: (id) => dispatch(selectedWorksite(id)),
  getWorksites: (paginationData, isResetState) => dispatch(getWorksites(paginationData, isResetState, true)), // To address the mismatch between cache and DB data when status changes
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(WorksiteList);
