import React, { useState, useEffect } from "react";

// Redux
import { connect } from "react-redux";
import {
  updateReportSettingsByAssetId,
  getReportSettingsByAssetId,
  resetReportSettingsByAssetId,
} from "../../../../redux/config/reports.actions";

import { bulkSettingsUpdate, resetNotificationSettingsFlags } from "../../../../redux/config/action";

import SnackBar from "../../SnackBar";
import Checkbox from "../../../../components/Checkbox/Checkbox.component";
// Custom Icons and Images
//import { ReactComponent as ImpactSVG } from "../../../../assets/Total_Incidents.svg";
//import { ReactComponent as NearMissSVG } from "../../../../assets/Total_Proximity_Incidents.svg";
//import { ReactComponent as UnauthoSVG } from "../../../../assets/Total_Unauthorized_Incidents.svg";
import ApplyToAllModal from "../../common/appyToAll/ApplyToAllModal";

import {
  SettingsContainer,
  SettingsFooter,
  SettingsBody,
  SettingTableDescription,
  RowDiv,
  Column,
  RowsContainer,
  ImpactSVG,
  NearMissSVG,
  UnauthoSVG,
} from "./ReportSettings.styles";

import { Trans, useTranslation } from "react-i18next";

import ActionButtons from "../../common/ActionButtons";
import SettingsHeader from "../common/SettingsHeader.component";
import { SettingsWrapper, NoAssetWrapper } from "../Settings.Styles";

const ReportSettings = ({
  loginDetails,
  isSiteAdmin,
  reportSettingsData,
  updateReportSettingsByAssetId,
  reportLoading,
  updateWorksiteReportSettingLoading,
  updateWorksiteReportSettingSuccess,
  accountId,
  getReportSettingsByAssetId,
  resetReportSettingsByAssetId,
  bulkSettingsUpdate,

  resetNotificationSettingsFlags,
  notificationUpdateLoading,
  notificationBulkUpdateSuccess,
  isSystemSettingsEditable,
  dropdownAccountId,
  isSuperAdmin,
}) => {
  const { t } = useTranslation();
  const [reportSettings, setReportSettings] = useState({
    impacts: true,
    nearMiss: true,
    unauthorizedUsage: true,
  });
  const [isReportSettingChanges, setIsReportSettingChanges] = useState(false);
  const [toasterStatus, setToasterStatus] = useState(false);
  const [showApplyToAllModal, setShowApplyToAllModal] = useState(false);
  const [checkApplyToAll, setCheckApplyToAll] = useState(false);
  const [applyAllSelectedAssetTypes, setApplyAllSelectedAssetTypes] = useState([]);
  const [applyToAllObj, setApplyToAllObj] = useState({});
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [clearSelection, setClearSelection] = useState(false);

  const settingsOptions = [
    {
      label: "All Worksites",
      isDropdown: false,
      id: "all",
      isCheckedKey: "all",
      radioGroup: "worksites",
    },
    {
      label: "Selected Worksites",
      id: "selected",
      isCheckedKey: "selected",
      isDropdown: true,
      labelKey: "name",
      radioGroup: "worksites",
      dropdown: {
        placeholder: "Select a Worksite",
      },
    },
    {
      label: "All Asset Types",
      isDropdown: false,
      id: "all",
      isCheckedKey: "all",
      radioGroup: "assetTypes",
    },
    {
      label: "Selected Asset Types",
      id: "selected",
      isCheckedKey: "selected",
      isDropdown: true,
      labelKey: "AssetTypeName",
      radioGroup: "assetTypes",
      dropdown: {
        placeholder: "Select an Asset Type",
      },
    },
    {
      label: "All Assets",
      isDropdown: false,
      id: "all",
      isCheckedKey: "all",
      radioGroup: "assets",
    },
    {
      label: "Selected Assets",
      id: "selected",
      isCheckedKey: "selected",
      isDropdown: true,
      labelKey: "assetName",
      radioGroup: "assets",
      dropdown: {
        placeholder: "Select an Asset",
      },
    },
  ];

  useEffect(() => {
    if (notificationBulkUpdateSuccess) {
      getReportSettingsByAssetId(selectedAssetId, setIsLoading);
      setApplyToAllObj({});
      setCheckApplyToAll(false);
      setClearSelection(true);
    }
  }, [notificationBulkUpdateSuccess]);

  useEffect(() => {
    return () => {
      handleCloseSnackBar();
    };
  }, [resetNotificationSettingsFlags]);

  const toggleApplyToAllModal = () => {
    setShowApplyToAllModal(!showApplyToAllModal);
  };

  const saveHandler = (isApplyAll, selectedItems) => {
    setCheckApplyToAll(isApplyAll || selectedItems.length > 0);
    toggleApplyToAllModal();
  };

  const handleCancel = () => {
    setReportSettings(reportSettingsData);
    setApplyToAllObj({});
    setCheckApplyToAll(false);
    setClearSelection(true);
  };

  const handleUpdate = () => {
    /*  let selectedWorksites = [];
    const worksiteId = isSiteAdmin ? loginDetails?.userData?.authorizedSites?.[0] : dropdownWorksiteId;

    if (applyToAllObj.worksites?.all) {
      selectedWorksites = ["all"];
    } else if (applyToAllObj.worksites?.selected) {
      applyToAllObj?.worksites?.selectedItems?.forEach((site) => selectedWorksites.push(site.value));

      if (!selectedWorksites.includes(worksiteId)) {
        selectedWorksites = [...selectedWorksites, worksiteId];
      }
    } else {
      selectedWorksites = [worksiteId];
    } */
    if (checkApplyToAll) {
      const siteIds = applyToAllObj?.worksites?.selectedItems?.map((site) => site.value);
      const assetTypeIds = applyToAllObj?.assetTypes?.selectedItems?.map((assetType) => assetType.value);
      const assetIds = applyToAllObj?.assets?.selectedItems?.map((asset) => asset.value);

      const updatableSettings = {
        accountId,
        sites: siteIds,
        assetTypes: assetTypeIds,
        assets: assetIds,
        settings: { reportSettings },
      };

      bulkSettingsUpdate(updatableSettings, setIsUpdateLoading);
    }
    updateReportSettingsByAssetId(selectedAssetId, reportSettings, setIsUpdateLoading);
  };

  const handleCloseSnackBar = () => {
    setApplyToAllObj({});
    setToasterStatus(false);
    setIsLoading(false);
    setIsUpdateLoading(false);
    resetNotificationSettingsFlags();
  };

  useEffect(() => {
    if (isLoading) {
      setApplyToAllObj({});
      setCheckApplyToAll(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (selectedAssetId) {
      setIsLoading(true);
      getReportSettingsByAssetId(selectedAssetId, setIsLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssetId]);

  useEffect(() => {
    setReportSettings(reportSettingsData);
  }, [reportSettingsData]);

  useEffect(() => {
    if (
      reportSettings?.impacts !== reportSettingsData?.impacts ||
      reportSettings?.nearMiss !== reportSettingsData?.nearMiss ||
      reportSettings?.unauthorizedUsage !== reportSettingsData?.unauthorizedUsage ||
      isApplyToAllObjValid()
    ) {
      setIsReportSettingChanges(true);
    } else {
      setIsReportSettingChanges(false);
    }
  }, [reportSettings, reportSettingsData, applyToAllObj]);

  useEffect(() => {
    if (updateWorksiteReportSettingSuccess) {
      setToasterStatus(true);
      setApplyToAllObj({});
      setCheckApplyToAll(false);
    }
  }, [updateWorksiteReportSettingSuccess]);

  const resetReportSettings = () => {
    resetReportSettingsByAssetId(selectedAssetId, setIsUpdateLoading);
  };

  const handleAssetIdFilter = (assetId) => {
    setSelectedAssetId(assetId);
  };

  const checkboxChangeHandler = (prop) => {
    setReportSettings({ ...reportSettings, [prop]: !reportSettings[prop] });
  };

  const isApplyToAllObjValid = () => {
    return (
      (applyToAllObj.worksites?.all ||
        (applyToAllObj.worksites?.selected && applyToAllObj.worksites?.selectedItems?.length > 0)) &&
      (applyToAllObj.assetTypes?.all ||
        (applyToAllObj.assetTypes?.selected && applyToAllObj.assetTypes?.selectedItems?.length > 0)) &&
      (applyToAllObj.assets?.all ||
        (applyToAllObj.assets?.selected && applyToAllObj.assets?.selectedItems?.length > 0))
    );
  };

  //TODO Please move the hardcoded rows to a JSON and render using map when time permits
  return (
    <SettingsWrapper>
      <SettingsHeader
        title={t("REPORT_SETTINGS_HEADER_LABEL")}
        handleAssetIdFilter={handleAssetIdFilter}
        selectedAssetId={selectedAssetId}
      />
      {isSuperAdmin && !dropdownAccountId ? (
        <NoAssetWrapper>
          <p>
            <Trans>SELECT_AN_ACCOUNT_TO_VIEW_SETTINGS</Trans>
          </p>
        </NoAssetWrapper>
      ) : selectedAssetId ? (
        <>
          {" "}
          <SettingsContainer>
            <SettingsBody>
              <SettingTableDescription>
                <RowDiv isHeader={true}>
                  <Column isHeader={true}>
                    <Trans>Report Type</Trans>
                  </Column>
                  <Column isHeader={true}></Column>
                  <Column isHeader={true}>
                    <Trans>Operator Photos</Trans>
                  </Column>
                </RowDiv>
                <RowsContainer>
                  <RowDiv>
                    <Column>
                      <ImpactSVG className="icon icon-impact" />
                      <Trans>Impacts</Trans>
                    </Column>
                    <Column></Column>
                    <Column textleft={"center"}>
                      {reportLoading || notificationUpdateLoading ? (
                        <div className="spinner-border spinner-border-sm" role="status"></div>
                      ) : (
                        <Checkbox
                          checked={reportSettings?.impacts}
                          onChange={() => checkboxChangeHandler("impacts")}
                          disabled={!isSystemSettingsEditable}
                        />
                      )}
                    </Column>
                  </RowDiv>
                  <RowDiv>
                    <Column>
                      <NearMissSVG className="icon icon-near-miss-pedestrian" />
                      <Trans>Pedestrian Near-Miss</Trans>
                    </Column>
                    <Column></Column>
                    <Column textleft={"center"}>
                      {reportLoading || notificationUpdateLoading ? (
                        <div className="spinner-border spinner-border-sm" role="status"></div>
                      ) : (
                        <Checkbox
                          checked={reportSettings?.nearMiss}
                          onChange={() => checkboxChangeHandler("nearMiss")}
                          disabled={!isSystemSettingsEditable}
                        />
                      )}
                    </Column>
                  </RowDiv>
                  {/* as part of SIER-7254 <RowDiv>
                    <Column>
                      <UnauthoSVG className="icon icon-total-unauthorized-incidents" />
                      <Trans>Unauthorized usage</Trans>
                    </Column>
                    <Column></Column>
                    <Column textleft={"center"}>
                      {reportLoading || notificationUpdateLoading ? (
                        <div className="spinner-border spinner-border-sm" role="status"></div>
                      ) : (
                        <Checkbox
                          checked={reportSettings?.unauthorizedUsage}
                          onChange={() => checkboxChangeHandler("unauthorizedUsage")}
                          disabled={!isSystemSettingsEditable}
                        />
                      )}
                    </Column>
                  </RowDiv> */}
                </RowsContainer>
              </SettingTableDescription>
            </SettingsBody>
          </SettingsContainer>
          <SettingsFooter>
            <ActionButtons
              showAppyToAll={true}
              updatableChanges={isReportSettingChanges}
              handleUpdate={handleUpdate}
              handleCancel={handleCancel}
              isLoading={updateWorksiteReportSettingLoading}
              applyToAll={isApplyToAllObjValid()}
              handleApplyToAll={toggleApplyToAllModal}
              showResetButton={true}
              handleReset={resetReportSettings}
            />
          </SettingsFooter>
          <ApplyToAllModal
            showModal={showApplyToAllModal}
            setShowModal={toggleApplyToAllModal}
            applyChanges={saveHandler}
            applyAllSelectedAssetTypes={applyAllSelectedAssetTypes}
            setApplyAllSelectedAssetTypes={setApplyAllSelectedAssetTypes}
            setCheckApplyToAll={setCheckApplyToAll}
            settingsOptions={settingsOptions}
            modalHeader={t("Report Settings")}
            applyToAllObj={applyToAllObj}
            setApplyToAllObj={setApplyToAllObj}
            accountId={accountId}
            clearSelection={clearSelection}
            setClearSelection={setClearSelection}
          />
        </>
      ) : (
        <NoAssetWrapper>
          <p>
            <Trans>NO_ASSET_FOUND</Trans>
          </p>
          <p>
            <Trans>EMPTY_ASSET</Trans>
          </p>
        </NoAssetWrapper>
      )}

      <SnackBar
        isSuccess={notificationBulkUpdateSuccess || isUpdateLoading}
        label={t("Report settings updated successfully")}
        handleClose={handleCloseSnackBar}
      />
    </SettingsWrapper>
  );
};

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSiteAdmin: state.user.isSiteAdmin,
  reportSettingsData: state.config.worksiteReportSettings,
  reportLoading: state.config.getWorksiteReportSettingsLoading,
  updateWorksiteReportSettingLoading: state.config.updateWorksiteReportSettingLoading,
  updateWorksiteReportSettingSuccess: state.config.updateWorksiteReportSettingSuccess,

  notificationBulkUpdateSuccess: state.notificationSetting?.notificationBulkUpdateSuccess,
  notificationUpdateLoading: state.notificationSetting?.notificationUpdateLoading,
  isSystemSettingsEditable: state.user.isSystemSettingsEditable,
  dropdownAccountId: state.dropdownFilters?.settings?.accountId,
  isSuperAdmin: state.user.isSuperAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  updateReportSettingsByAssetId: (assetId, data, setIsUpdateLoading) =>
    dispatch(updateReportSettingsByAssetId(assetId, data, setIsUpdateLoading)),
  getReportSettingsByAssetId: (assetId, setIsLoading) =>
    dispatch(getReportSettingsByAssetId(assetId, setIsLoading)),
  resetReportSettingsByAssetId: (assetId, setIsUpdateLoading) =>
    dispatch(resetReportSettingsByAssetId(assetId, setIsUpdateLoading)),
  bulkSettingsUpdate: (data, setIsUpdateLoading) => dispatch(bulkSettingsUpdate(data, setIsUpdateLoading)),
  resetNotificationSettingsFlags: () => dispatch(resetNotificationSettingsFlags()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportSettings);
