import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import WorksiteList from "./WorksiteList.component";
import Worksite from "./Worksite.component";
import { setEditAlertModal } from "../../../redux/modals/action";
import SearchBox from "../../../components/SearchBox/Searchbox.component";

const WorksiteManagement = ({ setEditAlertModal, editForm, editFormSecond }) => {
  const [SearchText, setSearchText] = useState("");
  const [filterAccountId, setFilterAccountId] = useState("all");
  const { t } = useTranslation();

  return (
    <Row className="mt-2">
      <Col lg={3}>
        <SearchBox
          label={t("Your Worksites")}
          placeholder={t("Search a Worksite")}
          searchText={SearchText}
          onChange={(e) => setSearchText(e.target.value)}
          setSearchText={(data) =>
            editForm && editFormSecond ? setSearchText(data) : setEditAlertModal(true)
          }
        />

        <WorksiteList
          style={{ boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 8px -6px" }}
          searchText={SearchText}
          filterAccountId={filterAccountId}
          setShowWorksiteDetail={() => {}}
        />
      </Col>

      <Col lg={9}>
        <Worksite
          setSearchText={setSearchText}
          searchText={SearchText}
          filterAccountId={filterAccountId}
          setFilterAccountId={setFilterAccountId}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  editForm: state.forms.editForm,
  editFormSecond: state.forms.editFormSecond,
});

const mapDispatchToProps = (dispatch) => ({
  setEditAlertModal: (data) => dispatch(setEditAlertModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorksiteManagement);
