import React from "react";

import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as Information } from "../../../../../assets/Information_Icon.svg";
import { ReactComponent as IconSuccess } from "../../../../../assets/AddIcon/IconRight.svg";
import { ReactComponent as Download } from "../../../../../assets/Download.svg";
import { ReactComponent as IconPartial } from "../../../../../assets/AddIcon/iconPartial.svg";
import { ReactComponent as IconFailure } from "../../../../../assets/AddIcon/iconFailure.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/AddIcon/Delete Button.svg";
import bulkUploadStatusType from "./bulkUploadStatusTypes";
import { resetBulkUploadAssetState } from "../../../../../redux/bulkUpload/action";
import UploadMessageComponent from "../../../../../components/BulkUploadComponents/UploadMessage.component";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

function AssetBulkUploadErrorHandle(props) {
  let { type, data, setActiveSection, localModalClose, resetBulkUploadAssetState, assetBulkUploadStatus } =
    props;

  const handleContinueButton = () => {
    localModalClose();
  };

  const handleReuploadButton = () => {
    resetBulkUploadAssetState();
    setActiveSection("uploadFiles");
  };

  return (
    <>
      {type === bulkUploadStatusType.BULK_UPLOAD_SUCCESS && (
        <UploadMessageComponent
          title="Assets Added"
          iconClassName="icon-select"
          uploadMessage="The file was uploaded successfully"
          additionalMessageSuccess={[
            "Items successfully added: ",
            <span className="greenSuccess">{`${assetBulkUploadStatus["Inserted Records"]}`}</span>,
          ]}
          handleContinueButton={handleContinueButton}
          ButtonLabelContinue="Continue"
          instructionModal={["An email has also been sent to your account with the details of assets added"]}
        />
      )}

      {type === bulkUploadStatusType.BULK_UPLOAD_INTERNAL_ERROR && (
        <UploadMessageComponent
          title="Upload Failed"
          iconClassName="icon-cancel"
          uploadMessage="The file was not uploaded"
          additionalMessageError={["An internal error occurred", "Please try uploading again"]}
          handleReuploadButton={handleReuploadButton}
          handleContinueButton={handleContinueButton}
          ButtonLabelReupload="Try Again"
          ButtonLabelContinue="Continue"
        />
      )}

      {type === bulkUploadStatusType.BULK_UPLOAD_PARTIAL_ADDED && (
        <UploadMessageComponent
          title="Assets Added"
          iconClassName="icon-exclamation-mark"
          uploadMessage="The file was uploaded with some errors"
          additionalMessageSuccess={[
            "Items successfully added: ",
            <span className="greenSuccess">{`${assetBulkUploadStatus["Inserted Records"]}`}</span>,
          ]}
          additionalMessageError={[
            "Items failed to add: ",
            <span className="redFailed">{`${assetBulkUploadStatus["Failed Records"]}`}</span>,
          ]}
          failedItemsDownloadLink={assetBulkUploadStatus["ErrorLog"]}
          handleReuploadButton={handleReuploadButton}
          handleContinueButton={handleContinueButton}
          ButtonLabelContinue="Continue"
          ButtonLabelReupload="Re Upload"
          userFailedCount
          instructionModal={[
            "The reasons for errors are listed in the CSV file of failed items",
            "Please remove the error column from the 'failed items' CSV file to avoid data mismatch errors",
          ]}
        />
      )}

      {type === bulkUploadStatusType.BULK_UPLOAD_EMPTY_FILE && (
        <UploadMessageComponent
          title="Unable to Add Assets"
          iconClassName="icon-cancel"
          uploadMessage="The assets were not added from the file"
          additionalMessageError={["The uploaded file is empty"]}
          handleReuploadButton={handleReuploadButton}
          handleContinueButton={handleContinueButton}
          ButtonLabelReupload="Re-Upload"
          ButtonLabelContinue="Continue"
          instructionModal={["Please enter the information of your employees in this file and upload again."]}
        />
      )}

      {type === bulkUploadStatusType.BULK_UPLOAD_DATA_MISMATCH && (
        <>
          <UploadMessageComponent
            title="Unable to Add Assets"
            iconClassName="icon-cancel"
            uploadMessage="The assets were not added from the file"
            additionalMessageError={[
              "Data mismatch in column",
              "Please remove extra data columns and upload again",
            ]}
            handleReuploadButton={handleReuploadButton}
            handleContinueButton={handleContinueButton}
            ButtonLabelReupload="Re-Upload"
            ButtonLabelContinue="Continue"
            instructionModal={["Please remove the error column from CSV file to avoid data mismatch errors."]}
          />
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  assetBulkUploadStatus: state.bulkUpload.assetBulkUploadFileUploadStatus,
});

const mapDispatchToProps = (dispatch) => ({
  resetBulkUploadAssetState: () => dispatch(resetBulkUploadAssetState()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetBulkUploadErrorHandle));
