import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { setPageNo, setPageSize } from "../../../../redux/deviceInformation/deviceInfo.action";
import DataTable from "../../../../components/DataTableComponent/DataTable.component";
import { tableHeader, paginationOptions } from "../constants";

const DeviceInfoTableWrapper = (props) => {
  const {
    setShowDeviceDetails,
    setSelectedAsset,
    deviceInformationList = [],
    selectedAccountId,
    setPageNo,
    setPageSize,
    pageNo,
    pageSize,
    totalCount,
    isLoading,
    loginDetails,
    isAccountOwner,
    rowHeight,
  } = props;
  const { t } = useTranslation();

  const accountId = isAccountOwner ? loginDetails?.accountId : selectedAccountId;

  const clickHandler = (selectedAsset) => {
    setSelectedAsset(selectedAsset);
    setShowDeviceDetails(true);
  };

  const ActionCell = ({ row }) => {
    return (
      <span
        style={{ color: "#0D5FBE", fontWeight: "bold", cursor: "pointer" }}
        onClick={() => clickHandler(row)}
        title={row.assetName}
      >
        {row.assetName || "NA"}
      </span>
    );
  };

  const columns = tableHeader.map((data) => {
    switch (data.name) {
      case "Worksite":
        return {
          name: data.name,
          selector: (row) => row?.siteId?.name || "NA",
          sortable: true,
        };
      case "Asset Name":
        return {
          name: data.name,
          selector: (row) => <ActionCell row={row} />,
          sortable: true,
          showTitle: false,
        };
      case "Asset Tag":
        return {
          name: data.name,
          selector: (row) => row?.lastDeviceInformation?.assetTag || "NA",
          sortable: true,
        };
      case "SIERA.AI Products":
        return {
          name: data.name,
          selector: (row) => row?.productType || "NA",
          sortable: true,
        };
      case "Last Seen":
        return {
          name: data.name,
          selector: (row) => {
            const lastSeenTimeStamp = moment(row?.lastSeenAt).year() > 2001 ? row?.lastSeenAt : null;
            return lastSeenTimeStamp ? moment(lastSeenTimeStamp).startOf("minute").fromNow() : "NA";
          },
          sortable: true,
        };
      default:
        return {
          name: data.name,
          selector: () => data.feildName,
          sortable: true,
        };
    }
  });

  return (
    <>
      <DataTable
        title=""
        columns={columns}
        data={deviceInformationList}
        responsive
        dense
        loading={isLoading}
        rowHeight={rowHeight}
        scrollable={true}
        fixedHeader
        pagination={accountId !== "all"}
        noDataMessage={accountId ? t("NO_RESULT_FOR_THE_SEARCH") : t("SELECT_ACCOUNT_FOR_DEVICE_INFORMATION")}
        pageSize={pageSize}
        pageNo={pageNo}
        totalPageReport={totalCount}
        handlePageLength={(e) => setPageSize(e.target.value)}
        previousPage={() => pageNo > 1 && setPageNo(pageNo - 1)}
        nextPage={() => pageNo * pageSize < totalCount && setPageNo(pageNo + 1)}
        paginationOptions={paginationOptions}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedAccountId: state.deviceInfo.selectedAccountId,
  accountListById: state.dropdownFilters.accountListById,
  deviceInformationList: state.deviceInfo.data,
  pageNo: state.deviceInfo.pageNo,
  pageSize: state.deviceInfo.pageSize,
  totalCount: state.deviceInfo.totalCount,
  isLoading: state.deviceInfo.isLoading,
  loginDetails: state.user.loginDetails,
  isAccountOwner: state.user.isAccountOwner,
});

const mapDispatchToProps = (dispatch) => ({
  setPageNo: (data) => dispatch(setPageNo(data)),
  setPageSize: (data) => dispatch(setPageSize(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceInfoTableWrapper);
