import styled from "styled-components";
export const InputWrapper = styled.div`
  margin-top: ${({ marginTop }) => marginTop};
`;

export const MFAWrapper = styled.div`
  @media (max-width: 1100px) {
  }
`;
export const SwitchOption = styled.div`
  @media (max-width: 1100px) {
    padding: 0px 10px;
  }
`;

export const SpanTop = styled.div`
  text-align: left;
  font-size: 16px !important;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Open Sans";
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
`;

export const SwitchWrapperDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Open Sans";
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"} !important;
  @media (max-width: 1100px) {
    display: block;
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  justify-content: end;
  @media (max-width: 1100px) {
    margin-bottom: 20px;
    justify-content: start;
  }
`;

export const LeftLabel = styled.div`
  float: left;
  margin-top: 10px;
`;

export const RightInput = styled.div`
  float: right;
  width: initial;
  text-align: right;
`;

export const LabelContainer = styled.div`
  flex: 1;
`;
export const DropWrapper = styled.div`
  width: 40%;
  margin-top: 10px;
  @media (max-width: 1100px) {
    width: 100%;
    clear: both;
    margin-top: 5px;
    float: left;
  }
`;

export const MfaAuthDivContainer = styled.div``;
export const SettingsWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  border-radius: 3px;
  width: 98%;
  margin-left: 0% !important;
  @media (max-width: 1100px) {
    margin-bottom: 20px;
    width: 100%;
  }
`;

export const MfaAuthContainer = styled.div`
  width: 100%;
  margin: auto;
`;

export const MfaAuthDiv = styled.div`
  width: 96%;
  margin: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SpanBottom = styled.div`
  text-align: left;
  font-size: 14px !important;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.grayL3 || "#676767"};
`;

export const Divider = styled.div`
  clear: both;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray5 || "#CCCCCC"};
`;
export const ButtonDiv = styled.div`
  text-align: right;
  @media (max-width: 1100px) {
    button[title="Cancel"] {
      float: left;
    }
  }
`;
export const ButtonWrapper = styled.div`
  width: 96%;
  margin: 10px auto;
  display: flex;
  justify-content: end;
  @media (max-width: 1100px) {
    display: block;
  }
`;

export const DivScroller = styled.div`
  clear: both;
  height: calc(100vh - 260px);
  overflow-y: auto;
  @media (max-width: 1100px) {
    height: calc(100vh - 350px);
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid  ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: green !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  }
`;
