import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updateActiveNavItem } from "../../../../../../redux/sideMenu/menu.action";
import Button from "../../../../../../components/Button/Button.component";
import { Trans, useTranslation } from "react-i18next";
import {
  ModalLibrary,
  ModalLibrarySection,
  ModalLeftLibraryAll,
  MainLibraryDiv,
  ContMainDiv,
  LibraryUl,
  ChartLibraryName,
  ChartLibraryStyledDiv,
  LibraryHeading,
  ButtonAddWrapp,
  IconUpSide,
  IconDownSide,
  ErroMessageStyle,
  NoteMessage,
  ChartButtonDiv,
  LoaderOverlay,
} from "./AddChartsModal.component.styles";
import SearchBox from "../../../../../../components/SearchBox/Searchbox.component";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  getAllChartsList,
  getUserChartsList,
  saveUserChartsList,
} from "../../../../../../redux/analyticsPage/action";
import { CacheRefs } from "../../../../../../utils/cache/CacheRefs";
import LoaderSkeletonList from "../LoaderSkeletonList/LoaderSkeletonList.component";
import SieraLoader from "../../../../../../components/SieraLoader/SieraLoader.component";

import { BlankMessageDiv, SearchTextHeighlight } from "../ChartsOverview.component.styles";

function AddChartsModal({
  updateActiveNavItem,
  handleClose,
  allChartsListRedux,
  getAllChartsList,
  saveUserChartsList,
  saveUserChartsListLoading,
  getUserChartsList,
  userChartListWithCatRedux,
  getUserChartsListLoading,
  getAllChartsListLoading,
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const [leftList, setLeftList] = useState([]); //TODO: have to remove these lists as well , have kept for ref in the below fns
  const [rightList, setRightList] = useState([]); //TODO: have to remove these lists as well , have kept for ref in the below fns

  const [allOtherChartsListWithCat, setAllOtherChartsListWithCat] = useState([]);
  const [allOtherChartsListWithCatFiltered, setAllOtherChartsListWithCatFiltered] = useState([]);
  const [userChartsListWithCat, setUserChartsListWithCat] = useState([]);

  const [deletedMainMenuIds, setDeletedMainMenuIds] = useState([]);

  const [isRightListDragMode, setIsRightListDragMode] = useState(true); // Start with drag-and-drop enabled
  const [showError, setShowError] = useState(false); // Track whether to show error
  const [errorMessage, setErrorMessage] = useState(""); // New state for error message

  const [searchValue, setSearchValue] = useState("");
  const [openMenuIds, setOpenMenuIds] = useState([]); // Start with the first menu open

  const [saveUserApiInProgress, setSaveUserApiInProgress] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isAllOtherChartListDataPresent, setisAllOtherChartListDataPresent] = useState(false);

  // Save button code
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [hasDeletions, setHasDeletions] = useState(false);
  const [hasMainMenuDeletions, setHasMainMenuDeletions] = useState(false);

  useEffect(() => {
    // updateActiveNavItem("analyticsPage");
    getAllChartsList();
    getUserChartsList(); //this will mostly fetch from cache ...!!using this api for user list since the getAllChartsList doesnt order charts based on usedId yet
  }, []);

  useEffect(() => {
    displayLoaderIfReq();
  }, [getAllChartsListLoading, getUserChartsListLoading]);

  const displayLoaderIfReq = () => {
    if (!getAllChartsListLoading && !getUserChartsListLoading) {
      setShowLoader(false);
    } else {
      setShowLoader(true);
    }
  };

  useEffect(() => {
    if (!allChartsListRedux || allChartsListRedux.length <= 0) {
      return;
    }
    processDataForUi();
    filterAllOtherChartsListWithCat();
  }, [allChartsListRedux]);

  useEffect(() => {
    if (!userChartListWithCatRedux || userChartListWithCatRedux.length <= 0) {
      return;
    }

    const newUserChartListWithCat = userChartListWithCatRedux.map((userChartListWithCatReduxItem) => {
      const catItem = {};
      catItem.id = userChartListWithCatReduxItem.cat;
      catItem.cat = userChartListWithCatReduxItem.cat;
      catItem.catLabel = userChartListWithCatReduxItem.catLabel;
      catItem.entries = userChartListWithCatReduxItem.entries;
      return catItem;
    });
    setUserChartsListWithCat(newUserChartListWithCat);
  }, [userChartListWithCatRedux]);

  useEffect(() => {
    filterAllOtherChartsListWithCat();
  }, [allOtherChartsListWithCat, searchValue]);

  const processDataForUi = () => {
    //fill user vs other arr...!!(rigtht vs left arr)

    const allOtherChartListWithCatObj = {};
    const userChartListWithCatObj = {};
    allChartsListRedux.map((chartsData) => {
      if (chartsData.alreadyAdded) {
        // if (!userChartListWithCatObj[chartsData.cat]) {
        //   userChartListWithCatObj[chartsData.cat] = [];
        // }
        // userChartListWithCatObj[chartsData.cat] = [...userChartListWithCatObj[chartsData.cat], chartsData];
        //user chart List is fetched from a diff apis so the above has been commented out
      } else {
        if (!allOtherChartListWithCatObj[chartsData.cat]) {
          allOtherChartListWithCatObj[chartsData.cat] = [];
        }
        allOtherChartListWithCatObj[chartsData.cat] = [
          ...allOtherChartListWithCatObj[chartsData.cat],
          chartsData,
        ];
      }
    });
    const allOtherChartListWithCatObjKeys = Object.keys(allOtherChartListWithCatObj);
    setAllOtherChartsListWithCat([]);
    setOpenMenuIds([]);
    allOtherChartListWithCatObjKeys.map((key) => {
      toggleSubMenu(key);
      const catItem = {};
      catItem.id = key;
      catItem.cat = key;
      catItem.entries = allOtherChartListWithCatObj[key];
      console.log("catItem.entries", catItem.entries);
      if (catItem.entries?.length > 0) {
        catItem.catLabel = catItem.entries[0].catLabel;
        // console.log("came here");
      } else {
        // console.log("came here instead");
        catItem.catLabel = key[0].toUpperCase() + key.substring(1);
      }
      setAllOtherChartsListWithCat((prevArray) => [...prevArray, catItem]);
    });

    // const userChartListWithCatObjKeys = Object.keys(userChartListWithCatObj);
    // setUserChartsListWithCat([]);
    // userChartListWithCatObjKeys.map((key) => {
    //   const catItem = {};
    //   catItem.id = key;
    //   catItem.cat = key;
    //   catItem.entries = userChartListWithCatObj[key];
    //   setUserChartsListWithCat((prevArray) => [...prevArray, catItem]);
    // });
    console.log("userCharts:allOtherChartsListWithCat", allOtherChartsListWithCat);
  };

  useEffect(() => {
    if (!saveUserChartsListLoading && saveUserApiInProgress) {
      setSaveUserApiInProgress(false); //turn off the flag to auto close the modal after saving
      setErrorMessage("");
      history.push("/app/analytics");
      handleClose();
      window.location.reload(true);
    }
  }, [saveUserChartsListLoading]);

  const saveChanges = () => {
    const isAnySectionWithSubMenu = userChartsListWithCat.some((menu) => menu.entries.length > 0);

    if (!isAnySectionWithSubMenu) {
      const errorMsg = t("CHART_TYPE_CANNOT_EMPTY");
      setErrorMessage(errorMsg);
      setShowError(true);
      setTimeout(() => {
        setErrorMessage("");
        setShowError(false);
      }, 5000);
      return;
    }

    setShowError(false);

    // const savedMenuData = userChartsList.map((menu) => {
    //   return {
    //     id: menu.id,
    //     text: menu.text,
    //     subMenu: menu.subMenu.map((submenu) => ({
    //       text: submenu,
    //       data: "Additional data for " + submenu,
    //     })),
    //   };
    // });
    // localStorage.setItem("savedMenuData", JSON.stringify(savedMenuData));

    let userListToSave = [];

    userChartsListWithCat.map((userChartListWithCatItem) => {
      userChartListWithCatItem.entries.map((userChartListWithCatEntriesItem) => {
        userListToSave = [...userListToSave, { _id: userChartListWithCatEntriesItem._id }];
      });
    });
    console.log("userChartsListWithCat::userListToSave", userListToSave);
    userListToSave = userListToSave.map((userlistToSaveItem, index) => {
      return { _id: userlistToSaveItem._id, order: index };
    });

    console.log("userChartsListWithCat", userChartsListWithCat);
    console.log("userChartsListWithCat::userListToSave", userListToSave);
    setSaveUserApiInProgress(true); //turn on the flag to auto close the modal after saving

    saveUserChartsList(userListToSave);
    CacheRefs.removeCacheForUserCharts();
  };

  const addItemToUiUserList = (mainMenu, submenu, sectionName) => {
    setUserChartsListWithCat((prevUserChartListWithCat) => {
      let chkItemAlreadyPresentUserChartListWithCat = false;
      let updatedUserChartListWithCat = prevUserChartListWithCat.map((userChartWithCatItem) => {
        if (userChartWithCatItem.cat == mainMenu.cat) {
          chkItemAlreadyPresentUserChartListWithCat = true;
          return {
            ...userChartWithCatItem,
            entries: submenu ? [...userChartWithCatItem.entries, submenu] : [...userChartWithCatItem.entries],
          };
        }
        return userChartWithCatItem;
      });

      if (!chkItemAlreadyPresentUserChartListWithCat) {
        updatedUserChartListWithCat.push(
          submenu ? { ...mainMenu, entries: [submenu] } : { ...mainMenu, entries: [] }
        );
      }
      console.log("updatedUserChartListWithCat", updatedUserChartListWithCat);
      return updatedUserChartListWithCat;
    });
  };

  const removeItemFromUiAllChartList = (mainMenu, submenu, sectionName) => {
    setAllOtherChartsListWithCat((prevAllOtherChartsListWithCat) => {
      const updatedLeftList = prevAllOtherChartsListWithCat.map((allOtherChartListWithCatItem) => {
        if (allOtherChartListWithCatItem.cat == mainMenu.cat) {
          const newSubMenuList = allOtherChartListWithCatItem.entries.filter((item) => {
            if (item._id != submenu._id) {
              return item;
            } else {
              console.log(item);
            }
          });
          console.log("userCharts:newSubMenuList", newSubMenuList);
          return { ...allOtherChartListWithCatItem, entries: newSubMenuList };
        }
        return allOtherChartListWithCatItem;
      });
      return updatedLeftList;
    });
    console.log("userCharts:allOtherChartsListWithCat", allOtherChartsListWithCat);
  };

  const onAddDivToRightList = (mainMenu, submenu, sectionName) => {
    console.log("came here ");
    // if (!deletedMainMenuIds.includes(mainMenu.id)) {
    addItemToUiUserList(mainMenu, submenu, sectionName);

    removeItemFromUiAllChartList(mainMenu, submenu, sectionName);
    // } else {
    //   const errorMsg = `Please add the ${sectionName} section first, to add chart in it.`;
    //   setErrorMessage(errorMsg);
    //   setShowError(true);
    //   setTimeout(() => {
    //     setErrorMessage("");
    //     setShowError(false);
    //   }, 5000);
    // }
    setUnsavedChanges(true);
  };

  const removeItemFromUiUserList = (mainMenu, submenu) => {
    setUserChartsListWithCat((prevUserChartsListWithCat) => {
      const updatedRightList = prevUserChartsListWithCat.map((userChartListWithCatItem) => {
        if (userChartListWithCatItem.cat == mainMenu.cat) {
          const newSubMenuList = userChartListWithCatItem.entries.filter((item) => {
            if (item._id != submenu._id) {
              return item;
            } else {
              console.log(item);
            }
          });
          console.log("userCharts:newUserChartListSubMenuList", newSubMenuList);
          return { ...userChartListWithCatItem, entries: newSubMenuList };
        }
        return userChartListWithCatItem;
      });
      return updatedRightList;
    });
    console.log("userCharts:UserChartsListWithCat", userChartsListWithCat);
  };

  const addItemToUiAllOtherChartsList = (mainMenu, submenu, sectionName) => {
    setAllOtherChartsListWithCat((prevAllOtherChartListCat) => {
      let chkItemAlreadyPresentAllOtherChartListWithCat = false;
      let updatedAllOtherChartListWithCat = prevAllOtherChartListCat.map((allOtherChartWithCatItem) => {
        if (allOtherChartWithCatItem.cat == mainMenu.cat) {
          chkItemAlreadyPresentAllOtherChartListWithCat = true;
          return {
            ...allOtherChartWithCatItem,
            entries: [...allOtherChartWithCatItem.entries, submenu],
          };
        }
        return allOtherChartWithCatItem;
      });
      if (!chkItemAlreadyPresentAllOtherChartListWithCat) {
        mainMenu.id = mainMenu.cat;
        updatedAllOtherChartListWithCat.push({ ...mainMenu, entries: [submenu] });
        console.log("addItemToUiAllOtherChartsList:mainMenu", mainMenu);
      }
      return updatedAllOtherChartListWithCat;
    });
    toggleSubMenu(mainMenu.cat, true);
    console.log("addItemToUiAllOtherChartsList:openMenuIds", openMenuIds);
    console.log("userCharts:allOtherChartsListWithCat", allOtherChartsListWithCat);
  };

  const deleteCatFromUiUserList = (mainMenu) => {
    setUserChartsListWithCat((prevUserChartListCat) => {
      let chkItemAlreadyPresentUserChartListWithCat = false;
      let updatedUserChartListWithCat = prevUserChartListCat.filter((userChartListWithCatItem) => {
        if (userChartListWithCatItem.cat != mainMenu.cat) {
          return userChartListWithCatItem;
        } else {
          chkItemAlreadyPresentUserChartListWithCat = true;
        }
      });
      return updatedUserChartListWithCat;
    });
  };

  const addCatToUiAllOtherChartsList = (mainMenu) => {
    setAllOtherChartsListWithCat((prevAllOtherChartListCat) => {
      let chkItemAlreadyPresentAllOtherChartListWithCat = false;
      let updatedAllOtherChartListWithCat = prevAllOtherChartListCat.map((allOtherChartWithCatItem) => {
        if (allOtherChartWithCatItem.cat == mainMenu.cat) {
          chkItemAlreadyPresentAllOtherChartListWithCat = true;
          return {
            ...allOtherChartWithCatItem,
            entries: [...allOtherChartWithCatItem.entries, ...mainMenu.entries],
          };
        }
        return allOtherChartWithCatItem;
      });
      if (!chkItemAlreadyPresentAllOtherChartListWithCat) {
        updatedAllOtherChartListWithCat.push(mainMenu);
      }
      return updatedAllOtherChartListWithCat;
    });
    console.log("userCharts:allOtherChartsListWithCat", allOtherChartsListWithCat);
  };

  // Inside onDeleteSubMenu function
  const onDeleteSubMenu = (mainMenu, submenu) => {
    removeItemFromUiUserList(mainMenu, submenu);
    addItemToUiAllOtherChartsList(mainMenu, submenu);

    setHasDeletions(true);
  };

  const handleCatDeleteFromUserList = (mainMenu) => {
    console.log("handleCatDeleteFromUserList", mainMenu);
    setHasMainMenuDeletions(true);

    setDeletedMainMenuIds((prevDeletedMainMenuIds) => [...prevDeletedMainMenuIds, mainMenu.cat]);
    deleteCatFromUiUserList(mainMenu);
    addCatToUiAllOtherChartsList(mainMenu);
    // setRightList((prevRightList) => {
    //   const deletedMenu = prevRightList.find((menu) => menu.id === mainMenu);
    //   if (deletedMenu) {
    //     // Move the submenus back to the left list
    //     setLeftList((prevLeftList) => {
    //       const updatedLeftList = prevLeftList.map((leftMenu) => {
    //         if (leftMenu.id === mainMenu) {
    //           return {
    //             ...leftMenu,
    //             subMenu: [...leftMenu.subMenu, ...deletedMenu.subMenu],
    //           };
    //         }
    //         return leftMenu;
    //       });
    //       return updatedLeftList;
    //     });
    //   }
    //   // Filter out the deleted div
    //   return prevRightList.filter((menu) => menu.id !== mainMenu);
    // });
  };

  const onAddDiv = (mainMenu) => {
    const newMainMenu = {
      id: mainMenu.id,
      cat: mainMenu.cat,
      catLabel: mainMenu.catLabel,
      entries: [],
    };
    console.log("mainMenu", mainMenu);
    console.log("newMainMenu", newMainMenu);
    // setRightList((prevRightList) => [...prevRightList, newMainMenu]);
    addItemToUiUserList(newMainMenu, null, mainMenu.catLabel);
    // Remove the main menu's ID from deletedMainMenuIds if it exists
    // setDeletedMainMenuIds((prevDeletedMainMenuIds) =>
    //   prevDeletedMainMenuIds.filter((id) => id !== mainMenu.id)
    // );
  };

  // Load saved menu data from localStorage when the component mounts
  useEffect(() => {
    const savedMenuData = localStorage.getItem("savedMenuData");
    if (savedMenuData) {
      const parsedMenuData = JSON.parse(savedMenuData);
      const updatedRightList = parsedMenuData.map((menu) => {
        return {
          id: menu.id,
          text: menu.text,
          subMenu: menu.subMenu.map((submenu) => submenu.text),
        };
      });
      setRightList(updatedRightList);
    }
  }, []);

  const toggleSubMenu = (mainMenuId, onlyOpen = false) => {
    console.log("toggleSubMenu:mainMenuId", mainMenuId);
    console.log("toggleSubMenu:openMenuIds", openMenuIds);
    setOpenMenuIds((prevOpenMenuIds) => {
      if (prevOpenMenuIds.includes(mainMenuId)) {
        // Close the sub-menu if it's already open
        console.log("toggleSubMenu:openMenuIds", "came here");
        if (onlyOpen) {
          return prevOpenMenuIds;
        } else {
          return prevOpenMenuIds.filter((id) => id !== mainMenuId);
        }
      } else {
        // Open the sub-menu and keep other sub-menus open
        console.log("toggleSubMenu:openMenuIds", "came here instead");
        return [...prevOpenMenuIds, mainMenuId];
      }
    });
    console.log("toggleSubMenu:openMenuIds", openMenuIds);
  };
  const filterAllOtherChartsListWithCat = () => {
    const newfilteredSubmenus = allOtherChartsListWithCat.filter((mainMenu) => {
      console.log(mainMenu);
      const uniqueSubmenus = mainMenu.entries
        .filter((submenu) => !userChartsListWithCat.some((menu) => menu.entries?.includes(submenu)))
        .filter((submenu, index, self) => self.indexOf(submenu) === index);

      let filteredSubMenuItems = uniqueSubmenus;

      if (searchValue.length >= 2) {
        filteredSubMenuItems = uniqueSubmenus.filter((submenu) =>
          submenu.name.toLowerCase().includes(searchValue.toLowerCase())
        );
      }
      if (filteredSubMenuItems?.length > 0) {
        return {
          ...mainMenu,
          isOpen: searchValue !== "" || openMenuIds.includes(mainMenu.id),
          entries: filteredSubMenuItems,
        };
      }
    });

    // console.log('filteredSubMenuItems:newfilteredSubmenus',newfilteredSubmenus)
    //sort the list before displaying ...!!
    newfilteredSubmenus.map((itemWithCat) => {
      const sortedEntries = itemWithCat.entries
        .sort((a, b) => (a.defaultOrder > b.defaultOrder ? 1 : -1))
        .map((sortedList) => sortedList);
      // console.log('filteredSubMenuItems:sortedEntries',sortedEntries)
      itemWithCat.entries = sortedEntries;
      return itemWithCat;
    });
    setAllOtherChartsListWithCatFiltered(newfilteredSubmenus);
    if (newfilteredSubmenus) {
      setisAllOtherChartListDataPresent(newfilteredSubmenus?.length > 0);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.type === "MAIN_MENU") {
      const reorderedMenus = Array.from(userChartsListWithCat);
      const [reorderedMenu] = reorderedMenus.splice(result.source.index, 1);
      reorderedMenus.splice(result.destination.index, 0, reorderedMenu);
      setUserChartsListWithCat(reorderedMenus);
    }

    if (result.type === "SUB_MENU") {
      const sourceMainMenu = userChartsListWithCat.find((menu) => menu.id === result.source.droppableId);
      const destinationMainMenu = userChartsListWithCat.find(
        (menu) => menu.id === result.destination.droppableId
      );

      if (sourceMainMenu && destinationMainMenu) {
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;
        const sourceMainMenuId = result.source.droppableId;

        setUserChartsListWithCat((prevUserChartListWithCat) => {
          const updatedRightList = [...prevUserChartListWithCat];
          const sourceMainMenu = updatedRightList.find((menu) => menu.id === sourceMainMenuId);
          const [draggedSubMenu] = sourceMainMenu.entries.splice(sourceIndex, 1);
          sourceMainMenu.entries.splice(destinationIndex, 0, draggedSubMenu);

          return updatedRightList;
        });
      }
    }

    if (result.type === "MAIN_MENU" || result.type === "SUB_MENU") {
      setUnsavedChanges(true);
      setHasDeletions(true);
      setHasMainMenuDeletions(true);
    }
  };

  const highlightSearchText = (text) => {
    if (searchValue) {
      const searchRegex = new RegExp(`(${searchValue})`, "gi");
      return text
        .split(searchRegex)
        .map((part, index) =>
          searchRegex.test(part) ? (
            <SearchTextHeighlight key={index}>{part}</SearchTextHeighlight>
          ) : (
            <span key={index}>{part}</span>
          )
        );
    }
    return text;
  };

  const handleSearchChange = (event) => {
    let searchVal = event?.target?.value;
    if (!searchVal) {
      searchVal = "";
    }
    setSearchValue(searchVal);
  };

  const isSaveButtonDisabled = () => {
    // The button is disabled if there are no unsaved changes
    return !unsavedChanges && !hasDeletions && !hasMainMenuDeletions;
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  return (
    <>
      <ModalLibrary>
        {showLoader && (
          <LoaderOverlay>
            <SieraLoader OnlyLoaderIcon size="small" />
          </LoaderOverlay>
        )}
        <ModalLibrarySection>
          <ModalLeftLibraryAll>
            <MainLibraryDiv>
              <ChartLibraryName>
                <SearchBox
                  iconOnly
                  isIcon
                  label={t("CHART_LIBRARY")}
                  value={searchValue}
                  onChange={handleSearchChange}
                  placeholder={t("SEARCH_CHART")}
                  width="100%"
                  searchText={searchValue}
                  setSearchText={handleSearchChange}
                />
              </ChartLibraryName>
              {/* <LoaderSkeletonList showLoader={showLoader} /> */}

              {!showLoader && (
                <ChartLibraryStyledDiv>
                  {!isAllOtherChartListDataPresent ? (
                    <BlankMessageDiv>
                      <p>
                        <Trans>NO_CHART_FOUND</Trans>.
                      </p>
                    </BlankMessageDiv>
                  ) : (
                    allOtherChartsListWithCatFiltered.map((mainMenu, mainIndex) => (
                      <ContMainDiv key={`${mainMenu?.id}-${mainIndex}`}>
                        <LibraryHeading onClick={() => toggleSubMenu(mainMenu?.id)}>
                          <span>{t(mainMenu?.catLabel)}</span>
                          {openMenuIds.includes(mainMenu?.id) ? (
                            <IconUpSide className="icon icon-arrow-up" />
                          ) : (
                            <IconDownSide className="icon icon-arrow-down" />
                          )}
                        </LibraryHeading>
                        <LibraryUl style={{ display: openMenuIds.includes(mainMenu?.id) ? "block" : "none" }}>
                          {mainMenu?.entries?.length === 0 && searchValue === "" ? (
                            <li className="NoMoreMessage">
                              {/* No more {mainMenu?.catLabel} charts are available */}
                              <Trans i18nKey="NO_MORE_CHARTS" values={{ catLabel: mainMenu?.catLabel }} />
                            </li>
                          ) : (
                            mainMenu?.entries
                              .filter((submenu) => {
                                return searchValue
                                  ? submenu.name.toLowerCase().includes(searchValue.toLowerCase())
                                  : true; // Show all items if no search value
                              })
                              ?.map((submenu, index) => (
                                <li key={`${submenu._id}-${index}`}>
                                  <i className="lefti"></i>
                                  <span title={submenu.name}>
                                    {t(highlightSearchText(truncateText(submenu.name)))}
                                  </span>
                                  <i
                                    onClick={() => onAddDivToRightList(mainMenu, submenu, mainMenu?.cat)}
                                    className={`back-add iconPlus icon icon icon-copy${
                                      mainMenu.isDeleted ? " disabled" : ""
                                    }`}
                                  ></i>
                                </li>
                              ))
                          )}
                        </LibraryUl>
                      </ContMainDiv>
                    ))
                  )}
                </ChartLibraryStyledDiv>
              )}
            </MainLibraryDiv>
          </ModalLeftLibraryAll>
          <ModalLeftLibraryAll>
            <MainLibraryDiv>
              <ChartLibraryName>
                <span>
                  <Trans>YOUR_CHART</Trans>
                </span>
              </ChartLibraryName>
              <ChartLibraryStyledDiv>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="right-list" type="MAIN_MENU" direction="vertical">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {userChartsListWithCat.map((mainMenu, mainIndex) => (
                          <Draggable
                            key={`${mainMenu.id}-${mainIndex}`}
                            draggableId={`${mainMenu.id}-${mainIndex}`}
                            index={mainIndex}
                            type="MAIN_MENU"
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ContMainDiv>
                                  <LibraryHeading>
                                    <i className="lefti icon icon-move-icon"></i>
                                    <span>{t(mainMenu.catLabel)}</span>
                                    <i
                                      onClick={() => handleCatDeleteFromUserList(mainMenu)}
                                      className="righti icon icon-delete"
                                    ></i>
                                  </LibraryHeading>
                                  <Droppable droppableId={mainMenu.id} type="SUB_MENU" direction="vertical">
                                    {(provided) => (
                                      <LibraryUl {...provided.droppableProps} ref={provided.innerRef}>
                                        {mainMenu.entries.length === 0 ? (
                                          <li className="NoMoreMessage">
                                            {/* Drag and drop a chart from {mainMenu.text} Chart Library */}
                                            <Trans
                                              i18nKey="DRAG_AND_DROP_CHART_FROM_LIBRARY"
                                              values={{ mainMenuText: mainMenu.text }}
                                            />
                                          </li>
                                        ) : (
                                          mainMenu.entries.map((submenu, subIndex) => (
                                            <Draggable
                                              key={`${submenu._id}-${subIndex}`}
                                              draggableId={`${submenu._id}-${subIndex}`}
                                              index={subIndex}
                                              type="SUB_MENU"
                                            >
                                              {(provided) => (
                                                <li
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                >
                                                  <i className="lefti icon icon-move-icon"></i>
                                                  <span title={submenu.name}>
                                                    {t(truncateText(submenu.name, 35))}
                                                  </span>
                                                  <i
                                                    onClick={() => onDeleteSubMenu(mainMenu, submenu)}
                                                    className="righti icon icon-delete"
                                                  ></i>
                                                </li>
                                              )}
                                            </Draggable>
                                          ))
                                        )}
                                        {provided.placeholder}
                                      </LibraryUl>
                                    )}
                                  </Droppable>
                                </ContMainDiv>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                <ButtonAddWrapp>
                  {allOtherChartsListWithCat.map((menu) => {
                    if (!userChartsListWithCat.some((item) => item.id === menu.id)) {
                      return (
                        <ChartButtonDiv>
                          <Button
                            label={"Add " + menu.catLabel + " Charts"}
                            key={menu.id}
                            onClick={() => onAddDiv(menu)}
                          />
                        </ChartButtonDiv>
                      );
                    }
                    return null;
                  })}
                </ButtonAddWrapp>
              </ChartLibraryStyledDiv>
            </MainLibraryDiv>
          </ModalLeftLibraryAll>
        </ModalLibrarySection>
        <NoteMessage>
          <Trans>ADD_CHARTS_DESC</Trans>
        </NoteMessage>
        {errorMessage && <ErroMessageStyle>{errorMessage}</ErroMessageStyle>}
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Button label="Cancel" buttonType="ghost" onClick={handleClose} style={{ marginRight: "10px" }} />
          <Button
            label="Save"
            onClick={saveChanges}
            disabled={isSaveButtonDisabled()} //disabled={rightList.length === 0}
          />
        </div>
      </ModalLibrary>
    </>
  );
}

const mapStateToProps = (state) => ({
  allChartsListRedux: state.analyticsPage?.getAllChartsList?.success,
  saveUserChartsListLoading: state.analyticsPage?.saveUserChartsListLoading,
  userChartListWithCatRedux: state.analyticsPage?.getUserChartsList?.success,
  getUserChartsListLoading: state.analyticsPage?.getUserChartsListLoading,
  getAllChartsListLoading: state.analyticsPage?.getAllChartsListLoading,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  getAllChartsList: () => dispatch(getAllChartsList()),
  saveUserChartsList: (listOfCharts) => dispatch(saveUserChartsList(listOfCharts)),
  getUserChartsList: () => dispatch(getUserChartsList()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddChartsModal));
